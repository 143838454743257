import React from 'react';
import PropTypes from 'prop-types';
import Chart from './Chart';

export default function PieChart(props) {
  const {
    title,
    data,
    seriesTitle,
    dataLabelsFormatter,
    tooltipValueSuffix,
  } = props;

  const dataLabels = {};
  if (dataLabelsFormatter) {
    dataLabels.formatter = dataLabelsFormatter;
  }
  const config = {
    chart: {
      type: 'pie',
    },
    xAxis: {
      type: 'linear',
    },
    yAxis: {
      title: {
        text: title,
      },
    },
    series: [
      {
        name: seriesTitle,
        data,
        dataLabels,
        tooltip: {
          valueSuffix: tooltipValueSuffix,
        },
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `<div style='white-space: normal; min-width: 400px' >
                  <b>${this.key}</b><br/>
                  <span style="color:${this.color}">\u25CF</span>
                  ${this.series.name}: <b>${this.point.y}</b><br/></div>`;
      },
    },
  };
  return <Chart config={config} />;
}

PieChart.propTypes = {
  xAxisType: PropTypes.string,
  title: PropTypes.string,
  seriesTitle: PropTypes.string,
  data: PropTypes.array.isRequired,
  tooltipValueSuffix: PropTypes.string,
  dataLabelsFormatter: PropTypes.func,
};

PieChart.defaultProps = {
  xAxisType: 'datetime',
};
